import React, { useContext } from 'react';
import { ConfigContext } from '@/base/context/Config/provider';
import withLayout from '@/base/containers/withLayout';
import SectionRichText from '@/components/sections/SectionRichText/SectionRichText';
import Link from '@/components/elements/Link';
import type { GlossaryDetailPageTemplateProps, GlossaryDetailPageTypes } from './GlossaryDetailPage.types';
import * as styles from './GlossaryDetailPage.module.scss';
import * as sidebarStyles from './Sidebar.module.scss';

export function GlossaryDetailPage({ name, content }: StoryblokPage<GlossaryDetailPageTypes>) {
  const {
    state: { config },
  } = useContext(ConfigContext);

  const { body, sidebar } = content;
  const { glossary_back_link, glossary_back_link_label } = config || {};

  return (
    <div className={styles.wrapper}>
      <div className={styles.backLinkWrapper}>
        <Link
          text={glossary_back_link_label}
          href={glossary_back_link?.cached_url}
          arrow_direction="left"
          type="internal"
          display_arrow
        />
      </div>
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <h1 className={styles.title}>{name}</h1>
          {body && <SectionRichText body={body} className={styles} />}
        </div>
        <div className={styles.sidebarWrapper}>
          <div className={styles.sidebar} data-without-background={String(!sidebar?.content?.[0].content)}>
            {!!sidebar?.content?.[0].content && <SectionRichText body={sidebar} className={sidebarStyles} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function GlossaryDetailPageTemplate({ pageContext, location }: GlossaryDetailPageTemplateProps) {
  return withLayout(GlossaryDetailPage, { ...pageContext, location });
}
