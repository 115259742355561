// extracted by mini-css-extract-plugin
export var headingH3 = "Sidebar-module--headingH3--3809a";
export var headingH4 = "Sidebar-module--headingH4--8fce3";
export var hr = "Sidebar-module--hr--d9cae";
export var link = "Sidebar-module--link--b369d";
export var list = "Sidebar-module--list--defc7";
export var listItem = "Sidebar-module--listItem--f080c";
export var listOl = "Sidebar-module--listOl--6cf18";
export var opacityEnter = "Sidebar-module--opacityEnter--81bd0";
export var opacityExit = "Sidebar-module--opacityExit--d95da";
export var paragraph = "Sidebar-module--paragraph--3409f";
export var rollDown = "Sidebar-module--rollDown--1305b";
export var rollUp = "Sidebar-module--rollUp--aaa76";
export var slideInDown = "Sidebar-module--slideInDown--86461";
export var slideOutUp = "Sidebar-module--slideOutUp--f4f85";
export var splashEnter = "Sidebar-module--splashEnter--040cc";
export var splashExit = "Sidebar-module--splashExit--501e9";