// extracted by mini-css-extract-plugin
export var backLinkWrapper = "GlossaryDetailPage-module--backLinkWrapper--4cfe0";
export var bold = "GlossaryDetailPage-module--bold--e19b2";
export var content = "GlossaryDetailPage-module--content--8afef";
export var contentWrapper = "GlossaryDetailPage-module--contentWrapper--6d69c";
export var headingH2 = "GlossaryDetailPage-module--headingH2--46622";
export var headingH3 = "GlossaryDetailPage-module--headingH3--f17a5";
export var headingH4 = "GlossaryDetailPage-module--headingH4--f4bf1";
export var headingH5 = "GlossaryDetailPage-module--headingH5--22eb6";
export var headingH6 = "GlossaryDetailPage-module--headingH6--1f5b1";
export var link = "GlossaryDetailPage-module--link--c4e5f";
export var linkWrapper = "GlossaryDetailPage-module--linkWrapper--cc557";
export var list = "GlossaryDetailPage-module--list--93605";
export var listItem = "GlossaryDetailPage-module--listItem--d1465";
export var listOl = "GlossaryDetailPage-module--listOl--4cdbb";
export var opacityEnter = "GlossaryDetailPage-module--opacityEnter--21ca5";
export var opacityExit = "GlossaryDetailPage-module--opacityExit--78c86";
export var paragraph = "GlossaryDetailPage-module--paragraph--517b0";
export var relatedContent = "GlossaryDetailPage-module--relatedContent--23a3b";
export var rollDown = "GlossaryDetailPage-module--rollDown--f8241";
export var rollUp = "GlossaryDetailPage-module--rollUp--a45a3";
export var sidebar = "GlossaryDetailPage-module--sidebar--f486d";
export var sidebarWrapper = "GlossaryDetailPage-module--sidebarWrapper--5346b";
export var slideInDown = "GlossaryDetailPage-module--slideInDown--6c659";
export var slideOutUp = "GlossaryDetailPage-module--slideOutUp--5a47f";
export var splashEnter = "GlossaryDetailPage-module--splashEnter--8cb8d";
export var splashExit = "GlossaryDetailPage-module--splashExit--3f218";
export var title = "GlossaryDetailPage-module--title--872d7";
export var wrapper = "GlossaryDetailPage-module--wrapper--79799";